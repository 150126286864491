import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { H4 } from '@system'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.secondary.main,
    marginBottom: '30px',
  },
}))

const ContactUsModule = (props) => {
  const classes = useStyles()

  return (
    <SbEditable content={props.blok}>
      <H4 className={classes.title}>{props.blok.title}</H4>
      <Box>{renderBloks(props.blok.contactNumbers)}</Box>
    </SbEditable>
  )
}

export default ContactUsModule
